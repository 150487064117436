















































































































import Vue, { VueConstructor } from "vue";
import Services from "@/services/services";
import ZTablePagination from "@zubut/common/src/components/ZTablePagination.vue";
import StatusService from "@zubut/common/src/components/StatusService.vue";
import ServiceTypeAvatar from "@zubut/common/src/components/ServiceTypeAvatar.vue";
import ZTableHeaderDropdown from "@zubut/common/src/components/ZTableHeaderDropdown.vue";
import ZDropdown from "@zubut/common/src/components/ZDropdown.vue";
import ZDropdownCities from "@/app/components/ZDropdownCities.vue";
import ServiceStatusConstants from "@/constants/services/status";
import { initialize } from "@/utils/array";
import { formatMoney } from "@/utils/money";
import { makeShortUUID } from "@/utils/strings";
import NotifyMixin from "@/mixins/notify.vue";
import PaginationMixin from "@/mixins/pagination.vue";
import { formatISO } from "@zubut/common/src/utils/time";
import UserServiceTypeOptions from "@/utils/user-service-type-options";
import DropdownOption from "@zubut/common/src/models/dropdown-option";
import HistoryService from "@zubut/common/src/models/services/history-service";
import ZTable from "@zubut/common/src/components/ZTable.vue";
import * as mutation from "@/store/modules/cities/mutations-types";

const extraFilters: DropdownOption[] = [
  { text: "Archivado", value: 12 },
  { text: "Con Incidencias", value: 13 },
  { text: "Recalculado", value: 14 }
];

export default (Vue as VueConstructor<
  Vue & InstanceType<typeof NotifyMixin> & InstanceType<typeof PaginationMixin>
>).extend({
  name: "DriverDetailServices",

  components: {
    ServiceTypeAvatar,
    StatusService,
    ZDropdown,
    ZDropdownCities,
    ZTable,
    ZTableHeaderDropdown,
    ZTablePagination
  },

  mixins: [NotifyMixin, PaginationMixin],

  data() {
    return {
      loading: true,
      services: [] as HistoryService[],
      sortBy: "createdAt",
      sortOrder: "desc",
      status: null,
      type: null,
      fields: [
        {
          key: "type",
          label: "",
          class: "text-center"
        },
        {
          key: "id",
          label: "Id Servicio",
          formatter: (value: string) => makeShortUUID(value)
        },
        {
          key: "createdAt",
          label: "Fecha",
          formatter: (value: string) =>
            formatISO(value, "PPPp").replace("a las ", "")
        },
        { key: "client", label: "Cliente" },
        {
          key: "cost",
          label: "Costo",
          formatter: (value: string) => formatMoney(value)
        },
        { key: "status", label: "Estado" }
      ],
      placeholderItem: {
        id: "",
        createdAt: null,
        user: "",
        cost: 0,
        status: 0,
        paymentStatus: 0,
        billingStatus: 0
      },
      hasError: false,
      where: {
        driversId: this.$route.params.id,
        cityId: this.$store.getters["cities/getCity"]
      } as any
    };
  },

  computed: {
    statusOptions(): DropdownOption[] {
      return [
        { text: "Todos", value: null },
        ...ServiceStatusConstants.completedOptions,
        ...extraFilters
      ];
    },

    selectedCity: {
      get(): string | null {
        return this.$store.getters["cities/getCity"];
      },
      set(val: string | null) {
        this.$store.commit(`cities/${mutation.SET_SELECTED_CITY}`, val);
      }
    },

    serviceTypeOptions(): DropdownOption[] {
      return UserServiceTypeOptions();
    }
  },

  watch: {
    currentPage: "getServices",
    perPage: "getServices",
    type: {
      handler() {
        this.servicesFilter();
      }
    },
    status: {
      handler() {
        this.statusFilter();
      }
    },
    selectedCity: {
      handler() {
        this.cityFilter();
      }
    }
  },

  beforeMount() {
    this.getServices();
  },

  methods: {
    getServices() {
      this.loading = true;
      if (this.where.cityId === null) {
        this.cityFilter();
      }
      Services.history({
        filter: this.filter,
        where: this.where
      })
        .then(({ data: services, meta }: any) => {
          if (meta.skip === 0) {
            this.totalRows = meta.count;
            this.services = initialize({
              value: this.placeholderItem,
              size: this.totalRows
            });
          }
          this.services.splice(meta.skip, services.length, ...services);
        })
        .catch(err => {
          this.$captureError(err);
          this.hasError = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    handleRowClick(item: HistoryService) {
      this.$router.push({ name: "serviceDetail", params: { id: item.id } });
    },

    chageSort() {
      this.sortOrder = this.sortOrder === "asc" ? "desc" : "asc";
      this.filter.order = "createdAt " + this.sortOrder;
      this.getServices();
    },

    servicesFilter() {
      if (this.type != null) {
        if (this.type === 1) {
          this.where = { ...this.where, type: [0, 1, 3] };
        } else {
          this.where = { ...this.where, type: this.type };
        }
      } else {
        delete this.where.type;
      }
      this.getServices();
    },

    statusFilter() {
      if (this.status != null) {
        this.where = { ...this.where, status: this.status };
      } else {
        delete this.where.status;
      }
      this.getServices();
    },

    cityFilter() {
      if (this.selectedCity != null) {
        this.where.cityId = this.selectedCity;
      } else {
        delete this.where.cityId;
      }
      this.getServices();
    }
  }
});
